import bookmark from './bookmark.png'
import lbookmark from './l-bookmark.png'
import unilogo from './unilogo.png'
import vline from './v-line.png'
import lmode from './l-mode.png'
import nmode from './n-mode.png'
import expo from './expologo.png'
import menud from './menu-d.png'
import menul from './menu-l.png'
import expol from './expol.png'
import backimage1 from './backimage1.jpg'
import logo from './expologo.png'
import facebook_icon from './facebook_icon.png'
import twitter_icon from './twitter_icon.png'
import linkedin_icon from './linkedin_icon.png'
import expom from './Expo.png'
import phone from './phone.png'
import email from './email.png'
import youtube_icon from './youtube_icon.png'
import up from './up.png'
import left from './left.png'
import right from './right.png'
import edify from './eDIFY.png'
import m1 from './m1.jpg'
import t1 from './t1.jpg'
import t2 from './t2.jpg'
import t3 from './t3.jpg'
import t4 from './t4.jpg'
import t5 from './t5.jpg'
import t6 from './t6.jpg'
import t7 from './t7.jpg'
import t8 from './t8.jpg'
import c1 from './c1.png'
import c2 from './c2.png'
import c3 from './c3.png'
import c4 from './c4.png'
import bg from './GalleryBack.jpg'

import MITLogo from './MIT Logo.png'
export const assets = {

   bookmark,unilogo,vline,lmode,nmode,lbookmark,expo,menud,expol,menul,backimage1,expom,facebook_icon,linkedin_icon,twitter_icon,logo,youtube_icon,
   email,phone,up,left,right,edify,m1,t1,t2,t3,t4,t5,t6,t7,t8,c1,c2,c3,c4,MITLogo,bg


}

