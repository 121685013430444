import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Navbar, Nav, Button, Container } from 'react-bootstrap';
import './Navbar.css';
import { assets } from '../../assets/assets';

const CustomNavbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLinkClick = (event) => {
    setIsMenuOpen(false);
    const targetId = event.target.getAttribute("href");
    const targetSection = document.querySelector(targetId);

    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleButtonClick = () => {
    window.open("https://emagazine.exposition.lk/", "_blank");
  };

  return (
    <>
      <Navbar bg="dark" variant="dark" expand="lg" className="custom-navbar fixed-top">
        <Container fluid>
          <Navbar.Brand href="#home">
            <img
              src={assets.edify}
              width="120"
              height="55"
              className="d-inline-block align-top navbar-logo"
              alt="Logo"
            />
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleToggle} />
          <Navbar.Collapse id="basic-navbar-nav" in={isMenuOpen}>
            <Nav className="mx-auto custom-nav">
              <Nav.Link href="#home" onClick={handleLinkClick}>Home</Nav.Link>
              <Nav.Link href="#about" onClick={handleLinkClick}>About</Nav.Link>
              <Nav.Link href="#container" onClick={handleLinkClick}>Guidelines</Nav.Link>
              <Nav.Link href="#containera" onClick={handleLinkClick}>Awards</Nav.Link>
              <Nav.Link href="#gallery" onClick={handleLinkClick}>Gallery</Nav.Link>
              <Nav.Link href="#footer" onClick={handleLinkClick}>Contact</Nav.Link>
            </Nav>
            <Button 
              variant="outline-light" 
              className="btn-e-magazine ms-lg-3" 
              onClick={handleButtonClick}
            >
              E-magazine
            </Button>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {isMenuOpen && <div className="overlay" onClick={handleToggle}></div>}
    </>
  );
};

export default CustomNavbar;
