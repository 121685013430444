// src/About.js
import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import aboutImage from '../../assets/expo 1.jpg'; // Adjust the path to your image
import './About.css';

const About = () => {
  const [fontSize, setFontSize] = useState({ h2: '3.5rem', p: '1.25rem' });
  const [isVisible, setIsVisible] = useState(false);
  const aboutRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 576) {
        setFontSize({ h2: '2rem', p: '1rem' }); // Adjust sizes for smaller screens
      } else {
        setFontSize({ h2: '3.5rem', p: '1.25rem' }); // Default sizes
      }
    };

    const handleScroll = () => {
      if (aboutRef.current) {
        const rect = aboutRef.current.getBoundingClientRect();
        if (rect.top < window.innerHeight && rect.bottom >= 0) {
          setIsVisible(true);
        } else {
          setIsVisible(false); // Reset visibility if scrolling away
        }
      }
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll);
    handleResize(); // Call initially to set sizes correctly

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className='my-5a'>
      <Container className="my-5" id="my-5" ref={aboutRef}>
        <Row className="align-items-center about-row">
          <Col md={6} className="text-column">
            <h2
              style={{ fontSize: fontSize.h2,fontFamily: 'Poppins, sans-serif',textAlign:'justify' }}
              className={isVisible ? 'fade-in' : ''}
            >
              What Is Edify?
            </h2>
            <p
  style={{ fontSize: fontSize.p, color: '#333', fontFamily: 'Poppins, sans-serif', textAlign: 'justify' }}
  className={isVisible ? 'fade-in' : ''}
>
  <span style={{ fontSize: '1.7rem', fontWeight: '5 00' }}>Edify</span> is an esteemed inter-university article competition, organized as part of Exposition by the Industrial Management Science Students’ Association of the Department of Industrial Management, Faculty of Science, University of Kelaniya.

  This competition provides a platform for university students across the country to showcase their insights on topics related to Management, IT, and Entrepreneurship. Winning articles are featured in the Exposition publication, alongside exclusive interviews with renowned figures in Sri Lanka, offering readers a unique blend of thought leadership and industry expertise.
</p>

          </Col>
          <Col md={6} className="image-column">
            <Image src={aboutImage} alt="Edify" fluid className={`about-image ${isVisible ? 'fade-in' : ''}`} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default About;
