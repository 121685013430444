import React from 'react';
import { Accordion, Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap CSS is imported
import './FAQs.css'; // Import your custom CSS

const FAQs = () => {
  const faqData = [
    {
      question: "Can I submit an article if I did not register for the competition?",
      answer: "No, only the articles from registered participants will be accepted for the evaluation."
    },
    {
      question: "Can I submit multiple articles across different themes (IT, Management, Entrepreneurship)?",
      answer: "No, one participant is allowed to submit only one article related to any selected theme."
    },
    {
      question: "Is there any specific formatting or citation style required for the articles?",
      answer: "Any recognized citation style is acceptable. However, we encourage you to use APA (American Psychological Association) formatting style for citations and references."
    },
    {
      question: "Will I receive a confirmation email once my article is successfully submitted?",
      answer: "Yes, upon successful submission of your article, you will receive a confirmation email acknowledging receipt. It is important to keep this email as a reference."
    },
    {
      question: "Is there a registration fee for participating in the competition?",
      answer: "No, there is no registration fee."
    },
    {
      question: "How do I know if I win the competition or not?",
      answer: "Winners will be announced on our official Facebook and Instagram pages."
    }
  ];

  return (
    <div className='my-5f'>
      <Container className="my-5">
        <Row>
          <Col>
            <h1 className="faq-header">FAQs</h1>
            <Accordion defaultActiveKey={null} className="accordion w-100" id="basicAccordion">
              {faqData.map((faq, index) => (
                <Accordion.Item 
                  eventKey={index.toString()} 
                  key={index} 
                  className={`accordion-item`}
                >
                  <Accordion.Header>
                    <strong>{faq.question}</strong> 
                  </Accordion.Header>
                  <Accordion.Body>
                    {faq.answer}
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default FAQs;
